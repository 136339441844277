// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-get-involved-equip-a-leader-js": () => import("./../../../src/pages/get-involved/equip-a-leader.js" /* webpackChunkName: "component---src-pages-get-involved-equip-a-leader-js" */),
  "component---src-pages-get-involved-js": () => import("./../../../src/pages/get-involved.js" /* webpackChunkName: "component---src-pages-get-involved-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-solicitation-disclosure-js": () => import("./../../../src/pages/solicitation-disclosure.js" /* webpackChunkName: "component---src-pages-solicitation-disclosure-js" */),
  "component---src-pages-stories-bringing-love-to-my-people-js": () => import("./../../../src/pages/stories/bringing-love-to-my-people.js" /* webpackChunkName: "component---src-pages-stories-bringing-love-to-my-people-js" */),
  "component---src-pages-stories-from-ashes-to-a-crown-of-beauty-js": () => import("./../../../src/pages/stories/from-ashes-to-a-crown-of-beauty.js" /* webpackChunkName: "component---src-pages-stories-from-ashes-to-a-crown-of-beauty-js" */),
  "component---src-pages-stories-js": () => import("./../../../src/pages/stories.js" /* webpackChunkName: "component---src-pages-stories-js" */),
  "component---src-pages-stories-my-passion-to-introduce-hope-js": () => import("./../../../src/pages/stories/my-passion-to-introduce-hope.js" /* webpackChunkName: "component---src-pages-stories-my-passion-to-introduce-hope-js" */)
}

