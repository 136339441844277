module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-M7TFW6S","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"hope-for-native-america","short_name":"h4na","start_url":"/","background_color":"#ee6c4e","theme_color":"#ee6c4e","display":"minimal-ui","icon":"src/images/h4na-logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b89cf5651bee96b36cd18830310eb530"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Arimo:400,400i,700,700i","Merriweather:300,300i,400,400i,500,500i,700,700i","Montserrat:200,200i,300,300i,400,400i,500,500i,600,700"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
